import React, { useState, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import RequestPasswordReset from 'components/auth/RequestPasswordReset'
import ResetPassword from 'components/auth/ResetPassword'
import { pipe, idFromKey, debugPopover, debugCopy, memoizeDebounce } from 'utils'
import { accessTokens, setAccessToken, clearAccessTokens } from 'api/accessTokenMgmt'
import api from 'api/api'
import queryClient from 'api/queryClient'
import app from './application.css.scss' // needed to load global styles for the app
import * as Sentry from '@sentry/react'
import { debounce } from 'lodash'
import {
    Route,
    useParams,
    useLocation,
    useNavigate,
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    RouterProvider,
} from 'react-router-dom'
import axios from 'axios'
import queryString from 'query-string'
import LoadingScreen from 'components/shared/LoadingScreen'
import Signup from 'components/auth/Signup'
import Login from 'components/auth/Login'
import StateProvider from 'components/StateProvider'
import EditProfile from 'components/users/EditProfile'
import TeamSettings from 'components/teams/TeamSettings'
import SpaceSettings from 'components/spaces/SpaceSettings'
import SpaceInvitation from 'components/spaces/SpaceInvitation'
import useStore from 'state/knovStore'
import KnovModal from 'components/shared/KnovModal'
import { cacheQuest } from 'state/cache'
import { Subject } from 'rxjs'
import { map, filter } from 'rxjs/operators'
import { Message, MsgTypes, MsgSource, replyTo, sendAndAwaitReply } from './messaging/messaging'
import fileTypeChecker from 'file-type-checker'
import questModel from 'components/quests/questModel'
import useStreamFilters, { insertCenter } from 'components/filters/useStreamFilters'
// Make logging available to the app.
import { logEv } from 'lib/log'

const eventBus$ = new Subject()

// NOTE: this function has to be in this file because we can't import anything
// from api/api while the app is still bootstrapping
export async function getGon(cache = true) {
    const tokens = accessTokens()
    const gon = await fetch(`${window.knovApiUrl}/api/v1/gon`, {
        method: 'GET',
        redirect: 'follow',
        headers: {
            ...tokens,
            Accept: 'application/json',
        },
    })
        .then(response => response.json())
        .then(gon => ({
            ...gon,
            // prefix the api endpoints returned from the backend with the
            // actual api location from window.knovApiUrl
            api: pipe(
                gon.api,
                Object.entries,
                apis => apis.map(([k, v]) => [k, `${window.knovApiUrl}${v}`]),
                Object.fromEntries,
            ),
        }))
        .catch(err => {
            console.error(err)
            redirectToLoginScreen()
        })
    if (window.KNOVIGATOR_IS_MOBILE && tokens && Object.keys(tokens)?.length > 1) {
        sendAndAwaitReply(
            eventBus$,
            new Message(JSON.stringify({ tokens, gon }), MsgSource.Web, MsgTypes.Creds),
            { timeout: 500, retries: 10 },
        )
    }
    return gon
}

function redirectToLoginScreen({ savePath = true } = { savePath: true }) {
    console.log('user is not logged in or token is expired, redirecting to login')
    const path = `${location.pathname}${location.search}`
    if (savePath && !path.startsWith('/login') && !path.startsWith('/signup')) {
        sessionStorage.setItem('attempted_path', path)
    }
    location.href = '/login'
}

async function initApp() {
    window.gon = await getGon()

    // Run non-critical initializations in parallel
    const initPromises = [
        // Sync active space to server
        gon.currentUser
            ? api.updateUser(gon.currentUser.id, { space_id: useStore.getState().activeSpaceId })
            : Promise.resolve(),
        // Initialize axios
        Promise.resolve(initAxios()),
        // Initialize service worker
        Promise.resolve(initServiceWorker()),
    ]

    // Only initialize analytics in production, and don't block on it
    if (gon.env === 'production') {
        try {
            setTimeout(() => {
                initAmplitude(window.gon?.currentUser?.id)
                initSentry()
            }, 0)
        } catch (err) {
            console.error('Failed to initialize analytics:', err)
        }
    }

    try {
        await Promise.all(initPromises)
    } catch (err) {
        console.error('Error during app initialization:', err)
    }

    return gon?.currentUser
}

const withAppLoader = WrappedComp => props => {
    const [loading, setLoading] = useState(true)
    const [finalProps, setFinalProps] = useState(props)
    const navigate = useNavigate()

    useEffect(function load() {
        ;(async function awaitLoad() {
            const currentUser = await initApp()

            // if load function provided use it, otherwise load StateProvider.
            if (props.load instanceof Function) {
                let [loaded, loadedProps] = await props.load(props)
                if (loaded) {
                    setFinalProps({ ...props, ...(loadedProps || {}) })
                    setLoading(false)
                } else if (currentUser)
                    // Add message that logged in user doesn't have access to attempted url.
                    navigate('/')
                else redirectToLoginScreen()
            } else if (currentUser || props.load) {
                setLoading(false)
            } else {
                redirectToLoginScreen()
            }
        })()
    }, [])

    return (
        <div className="with-app-loader">
            {loading ? <LoadingScreen /> : <WrappedComp {...finalProps} />}
        </div>
    )
}

const withLayout = WrappedComp => props => {
    return (
        <>
            <div className="container-fluid" style={{ background: '#eee' }}>
                <div className="row">
                    <div className="col-md-offset-3 col-md-6">
                        <div className="row">
                            <WrappedComp {...props} />
                        </div>
                    </div>
                </div>
            </div>
            <KnovModal />
        </>
    )
}

function UserSettingsLoader(props) {
    const UserSettingsWithLoader = withLayout(withAppLoader(EditProfile))
    return <UserSettingsWithLoader {...props} />
}

function StreamSettingsLoader(props) {
    const StreamSettingsWithLoader = withLayout(withAppLoader(TeamSettings))
    return <StreamSettingsWithLoader {...props} />
}

function SpaceSettingsLoader(props) {
    const SpaceSettingsWithLoader = withLayout(withAppLoader(SpaceSettings))
    return <SpaceSettingsWithLoader {...props} />
}

function SpaceInvitationLoader(props) {
    const SpaceInviteWithLoader = withLayout(withAppLoader(SpaceInvitation))
    return <SpaceInviteWithLoader {...props} />
}

function StateProviderLoader(props) {
    const StateProviderWithLoader = withAppLoader(StateProvider)
    return (
        <div className="state-provider-loader">
            <StateProviderWithLoader {...props} />
        </div>
    )
}

function LoginRedirector() {
    try {
        const tokenStr = JSON.parse(localStorage.getItem('token'))
        // TODO but what if it's the wrong token, for example if you switched users in the same browser ?
        if (tokenStr['access-token']) {
            return <StateProviderLoader />
        }
    } catch (e) {
        console.log('Error retrieving accesstoken!', e)
    }
    return <Navigate to="/login" />
}

async function registerImageAuthWorker() {
    if ('serviceWorker' in navigator) {
        try {
            console.log('[ImageAuthSetup] Registering service worker...')
            const registration = await navigator.serviceWorker.register('/imageAuthWorker.js')
            console.log('[ImageAuthSetup] Service worker registered:', registration)

            // Send initial tokens to the service worker
            if (registration.active) {
                console.log('[ImageAuthSetup] Service worker is active, sending initial tokens')
                const tokenStr = localStorage.getItem('token')
                if (tokenStr) {
                    try {
                        const tokens = JSON.parse(tokenStr)
                        console.log('[ImageAuthSetup] Sending initial tokens to worker')
                        registration.active.postMessage({
                            type: 'STORE_TOKENS',
                            tokens: tokens,
                        })
                    } catch (e) {
                        console.error('[ImageAuthSetup] Error parsing initial tokens:', e)
                    }
                } else {
                    console.log('[ImageAuthSetup] No initial tokens found in localStorage')
                }
            }

            // Listen for token requests from the service worker
            navigator.serviceWorker.addEventListener('message', event => {
                console.log('[ImageAuthSetup] Received message from worker:', event.data)
                if (event.data && event.data.type === 'GET_TOKENS') {
                    console.log('[ImageAuthSetup] Worker requested tokens')
                    const tokenStr = localStorage.getItem('token')
                    console.log('[ImageAuthSetup] Found tokens in localStorage:', !!tokenStr)

                    // Make sure we have a port to respond to
                    if (event.ports && event.ports[0]) {
                        console.log('[ImageAuthSetup] Sending tokens through message port')
                        event.ports[0].postMessage(tokenStr)
                    } else {
                        console.error('[ImageAuthSetup] No message port available to respond')
                        // Fallback to direct response if no port
                        event.source.postMessage({
                            type: 'TOKEN_RESPONSE',
                            tokens: tokenStr,
                        })
                    }
                }
            })
        } catch (error) {
            console.error('[ImageAuthSetup] Service worker registration failed:', error)
        }
    } else {
        console.error('[ImageAuthSetup] Service workers not supported')
    }
}

async function registerServiceWorker() {
    if ('serviceWorker' in navigator) {
        let registration
        try {
            registration = await navigator.serviceWorker.register('/service-worker.js')
            console.log('[Companion]', 'Service worker registered!', registration)
        } catch (error) {
            console.error('[Companion]', 'Failed to register service worker:', error)
        }

        if (registration && 'PushManager' in window && window.vapidPublicKey && gon?.currentUser) {
            try {
                const { pushes_allowed } = gon?.currentUser

                if (pushes_allowed === null) {
                    const pushModal = document.querySelector('#push-modal')
                    pushModal?.classList.add('show')
                } else if (pushes_allowed) {
                    if (!('Notification' in window) || Notification.permission === 'denied') {
                        console.error(
                            'This browser does not support notifications or permission has been denied',
                        )
                        return
                    }

                    if (Notification.permission !== 'granted') {
                        const permission = await Notification.requestPermission()
                        if (permission !== 'granted') {
                            console.error('Permission to receive notifications has been denied')
                            return
                        }
                    }

                    try {
                        const pushSubscription = await registration.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: window.vapidPublicKey,
                        })

                        const subsUrl = `${window.knovApiUrl}/subscription`
                        const pushResp = await fetch(subsUrl, {
                            method: 'POST',
                            body: JSON.stringify({ push_subscription: pushSubscription }),
                            redirect: 'follow',
                            headers: { ...accessTokens(), 'Content-Type': 'application/json' },
                        })
                    } catch (error) {
                        console.error('[Companion]', 'Failed to subscribe to push manager:', error)
                    }
                }
            } catch (error) {
                console.error('[Companion]', 'Failed to subscribe to push manager:', error)
            }
        }
    }
}

function initServiceWorker() {
    if (process.env.NODE_ENV === 'production') {
        registerServiceWorker()
    }
    // Always register the image auth worker as it's needed for both dev and prod
    //registerImageAuthWorker()
}

function getSubdomain() {
    // If the hostname is either a localhost, a numeric IP (ie a 192.168.*
    // local address), or has only two parts (indicating a primary domain like
    // treechat.ai), return null to indicate no subdomain.
    const hostnameParts = window.location.hostname.split('.')
    const isNumericIP = hostnameParts.every(part => Number.isInteger(Number(part)))
    const isLocalhost = hostnameParts[hostnameParts.length - 1] === 'localhost' || isNumericIP

    if (isLocalhost || hostnameParts.length === 2) {
        return null
    }

    return hostnameParts[0]
}

async function mountKnovApp() {
    try {
        const specialCaseSpaces = ['app', 'staging', 'home']
        let subdomain = getSubdomain() || 'app'
        let tgtSpaceId

        if (specialCaseSpaces.includes(subdomain)) {
            // knovigator is the default space for now
            subdomain = 'treechat'
        }

        if (!specialCaseSpaces.includes(subdomain)) {
            tgtSpaceId = await fetch(
                `${window.knovApiUrl}/api/v1/spaces/subdomain_to_id?subdomain=${subdomain}`,
            )
                .then(response => response.text())
                .catch(err => console.error(err))
        }
        console.log('>>> launching with subdomain and space id', subdomain, tgtSpaceId)
        const state = useStore.getState()
        state.set({ activeSpaceId: tgtSpaceId })
        logEv('LOAD SPACE', { subdomain, spaceId: tgtSpaceId })
    } catch (e) {
        console.log('>>> error while loading space from subdomain!', e)
    }

    try {
        initMobileAppEventHandlers()
        const router = createBrowserRouter(
            createRoutesFromElements([
                <Route path="/" element={<LoginRedirector />} />,
                <Route path="/login" element={<Login />} />,
                <Route
                    path="/signup"
                    element={(() => {
                        const SignupLoader = () => {
                            const { search } = useLocation()
                            const { space_invite, stream_invite, wallet } =
                                queryString.parse(search)
                            return (
                                <Signup
                                    space_invite_token={space_invite}
                                    stream_invite_token={stream_invite}
                                    wallet={wallet}
                                />
                            )
                        }

                        return <SignupLoader />
                    })()}
                />,
                <Route
                    path="/auth/password/request-reset"
                    element={(() => {
                        const RequestPasswordResetLoader = () => {
                            const RequestPasswordResetWithLoader =
                                withAppLoader(RequestPasswordReset)
                            return <RequestPasswordResetWithLoader load />
                        }

                        return <RequestPasswordResetLoader />
                    })()}
                />,
                <Route
                    path="/auth/password/edit"
                    element={(() => {
                        const ResetPasswordLoader = () => {
                            const { search } = useLocation()
                            const { reset_password_token } = queryString.parse(search)
                            const ResetPasswordWithLoader = withAppLoader(ResetPassword)
                            return (
                                <ResetPasswordWithLoader
                                    load
                                    resetPasswordToken={reset_password_token}
                                />
                            )
                        }

                        return <ResetPasswordLoader />
                    })()}
                />,

                <Route path="/profile" element={<UserSettingsLoader />} />,

                <Route
                    path="/user/:userKey"
                    element={(() => {
                        // due to hooks + react-router, create a mini component just to route the data in.
                        // note that this mini component is being called as an IIFE! (note the trailing `()`)
                        const UserLoader = () => {
                            let { userKey } = useParams()
                            const userId = idFromKey(userKey)
                            return <StateProviderLoader filter={{ user: userId }} />
                        }

                        return <UserLoader />
                    })()}
                />,

                <Route
                    path="/stream/people"
                    element={<StateProviderLoader filter={{ people: true, order: 'time' }} />}
                />,

                <Route
                    path="/people/:participantKey"
                    element={<StateProviderLoader filter={{ people: true, order: 'time' }} />}
                />,

                <Route
                    path={'/stream/public'}
                    element={(() => {
                        return (
                            <StateProviderLoader
                                load={true}
                                filter={{ public: true, order: 'time' }}
                            />
                        )
                    })()}
                />,

                <Route
                    path={'/stream/treechat'}
                    element={(() => {
                        return (
                            <StateProviderLoader
                                load={true}
                                filter={{ treechat: true, order: 'time' }}
                            />
                        )
                    })()}
                />,

                <Route
                    path={'/stream/hodlocker'}
                    element={(() => {
                        return (
                            <StateProviderLoader
                                load={true}
                                filter={{ hodlocker: true, order: 'time' }}
                            />
                        )
                    })()}
                />,

                <Route
                    path={'/stream/twetch'}
                    element={(() => {
                        return (
                            <StateProviderLoader
                                load={true}
                                filter={{ twetch: true, order: 'time' }}
                            />
                        )
                    })()}
                />,

                <Route
                    path={'/stream/history'}
                    element={(() => {
                        return (
                            <StateProviderLoader
                                load={true}
                                filter={{ history: true, order: 'time' }}
                            />
                        )
                    })()}
                />,

                <Route
                    path={'/stream/starred'}
                    element={(() => {
                        return (
                            <StateProviderLoader
                                load={true}
                                filter={{ starred: true, order: 'time' }}
                            />
                        )
                    })()}
                />,

                <Route
                    path="/stream/all"
                    element={<StateProviderLoader filter={{ all: true, order: 'time' }} />}
                />,

                <Route
                    path="/stream/private"
                    element={<StateProviderLoader filter={{ private: true, order: 'time' }} />}
                />,

                <Route
                    path="/stream/link-access"
                    element={<StateProviderLoader filter={{ link: true, order: 'time' }} />}
                />,

                <Route
                    path="/stream/clips"
                    element={<StateProviderLoader filter={{ clip: true, order: 'time' }} />}
                />,

                <Route
                    path="/stream/public-clips"
                    element={<StateProviderLoader filter={{ public_clip: true, order: 'time' }} />}
                />,

                <Route /* this is an alias of /stream/link */
                    path="/stream/shared"
                    element={<StateProviderLoader filter={{ link: true, order: 'time' }} />}
                />,

                <Route
                    path="/stream/notifications"
                    element={<StateProviderLoader filter={{ notifications: true }} />}
                />,

                <Route
                    path="/stream/:streamId/settings"
                    element={(() => {
                        // due to hooks + react-router, create a mini component just to route the data in.
                        // note that this mini component is being called as an IIFE! (note the trailing `()`)
                        const StreamSettingsParams = () => {
                            let { streamId } = useParams()
                            return <StreamSettingsLoader teamId={streamId} />
                        }

                        return <StreamSettingsParams />
                    })()}
                />,

                <Route
                    path="/stream/:streamId"
                    element={(() => {
                        // due to hooks + react-router, create a mini component just to route the data in.
                        // note that this mini component is being called as an IIFE! (note the trailing `()`)
                        const StreamLoader = () => {
                            let { streamId } = useParams()
                            return (
                                <StateProviderLoader
                                    streamId={streamId}
                                    filter={{ team_ids: [streamId] }}
                                />
                            )
                        }

                        return <StreamLoader />
                    })()}
                />,

                <Route
                    path="/meme/:memeText/stream/:streamId"
                    element={(() => {
                        // due to hooks + react-router, create a mini component just to route the data in.
                        // note that this mini component is being called as an IIFE! (note the trailing `()`)
                        const MemeLoader = () => {
                            let { memeText } = useParams()
                            memeText = memeText ? decodeURIComponent(memeText) : null
                            memeText = memeText?.split('-').join(' ')
                            return (
                                <StateProviderLoader
                                    memeText={memeText}
                                    filter={{ meme: memeText }}
                                />
                            )
                        }

                        return <MemeLoader />
                    })()}
                />,

                <Route
                    path="/meme/:memeText"
                    element={(() => {
                        // due to hooks + react-router, create a mini component just to route the data in.
                        // note that this mini component is being called as an IIFE! (note the trailing `()`)
                        const MemeLoader = () => {
                            let { memeText } = useParams()
                            memeText = memeText ? decodeURIComponent(memeText) : null
                            memeText = memeText?.split('-').join(' ')
                            return (
                                <StateProviderLoader
                                    memeText={memeText}
                                    filter={{ meme: memeText, all: true }}
                                />
                            )
                        }

                        return <MemeLoader />
                    })()}
                />,

                <Route
                    path="/quest/:questKey"
                    element={(() => {
                        // due to hooks + react-router, create a mini component just to route the data in.
                        // note that this mini component is being called as an IIFE! (note the trailing `()`)
                        const QuestLoader = () => {
                            const { hash } = useLocation()
                            const { questKey } = useParams()
                            const questId = idFromKey(questKey)

                            let canView
                            const load = async () => {
                                canView = await api.getQuestPerms(
                                    questId,
                                    window.gon.currentUser?.id,
                                )

                                return [canView]
                            }

                            return (
                                <StateProviderLoader load={load} filter={{ questId }} hash={hash} />
                            )
                        }

                        return <QuestLoader />
                    })()}
                />,

                <Route
                    path="/new"
                    element={(() => {
                        const QuestLoader = () => {
                            const load = () => {
                                const filter = { private: true }
                                const draftQuest = questModel.newQuestFromFilter({}, filter)
                                cacheQuest(draftQuest)

                                return [true, { filter: { questId: draftQuest.id } }]
                            }
                            return <StateProviderLoader load={load} />
                        }

                        return <QuestLoader />
                    })()}
                />,

                <Route
                    path="/spaces/:spaceId/settings"
                    element={(() => {
                        // due to hooks + react-router, create a mini component just to route the data in.
                        // note that this mini component is being called as an IIFE! (note the trailing `()`)
                        const SpaceSettingsParams = () => {
                            let { spaceId } = useParams()
                            return <SpaceSettingsLoader spaceId={spaceId} />
                        }

                        return <SpaceSettingsParams />
                    })()}
                />,
                <Route
                    path="/spaces/invitation"
                    element={(() => {
                        // due to hooks + react-router, create a mini component just to route the data in.
                        // note that this mini component is being called as an IIFE! (note the trailing `()`)
                        const SpaceInvitationParams = () => {
                            const { search } = useLocation()
                            const { space_invite } = queryString.parse(search)

                            const load = async () => {
                                // space_invite represents the space invitation id.
                                const invitation = await api.getSpaceInvitationByToken(space_invite)
                                if (invitation) {
                                    return [true, { invitation }]
                                } else {
                                    // TODO (?): handle this better than just redirecting to login.
                                    return [false]
                                }
                            }

                            return <SpaceInvitationLoader load={load} />
                        }

                        return <SpaceInvitationParams />
                    })()}
                />,
            ]),
        )

        const container = document.getElementById('knovigator-body')
        const root = createRoot(container)
        const app = <RouterProvider router={router} />
        root.render(app)
    } catch (e) {
        console.log('Error loading knov!', e)
        // TODO: we can use react now, so handle this with react
        // react isn't loaded yet, so use old school dom manipulation to add a
        // class to the spinner elem and show the err msg if something failed
        const errorElem = document.querySelector('body')

        const preElem = document.createElement('pre')
        preElem.style = 'text-align: left;'
        preElem.textContent = e.stack

        errorElem.appendChild(preElem)
    }
}

const handleMobileNotiClicked = debounce(function handleMobileNotiClick(notiEv) {
    console.log('>>> noti ev received: ', notiEv)

    let content = notiEv?.notification?.request?.content

    // sometimes noti content gets sent through stringified, so try to parse it
    // as json if we get a string
    if (typeof content === 'string') {
        try {
            content = JSON.parse(content)
        } catch (e) {
            console.log('>>> failed to parse stringified content: ', e)
        }
    }

    const { body, title, data } = content ?? {}
    const { quest_id, answer_id } = data ?? {}

    if (quest_id) {
        insertCenter({ questId: quest_id, answerId: answer_id })
        // bandaid to ensure quest loads properly, sometimes frontend navs to quest but fails to
        // load quest data
        setTimeout(() => {
            queryClient.refetchQueries({ queryKey: ['quest', quest_id], exact: true })
        })
    } else {
        console.log('>>> no quest_id found in notification data')
        useStreamFilters().selectNotifications()
    }
}, 300)

const redirectToQuest = debounce(
    async function redirectToQuest(quest) {
        const {
            actions: { panelActions },
        } = useStore.getState()

        const doRedirToQuest = async () => {
            try {
                insertCenter({ questId: quest.id })
            } catch (err) {
                // debugPopover(`error getting quest: ${err}`)
            }
        }

        doRedirToQuest()
    },
    1000,
    { leading: false, trailing: true },
)

// debounced to prevent double clips
const handleUrlClip = memoizeDebounce(
    ({ data: url, content, destination }) => {
        if (!url) return
        // TODO PANEL REFACTOR render center loading indicator?
        return api
            .clipLink({
                url,
                ...(destination ? { destination } : {}),
                ...(content ? { content } : {}),
            })
            .then(clippedQuest => {
                redirectToQuest(clippedQuest)
            })
    },
    2000,
    { leading: true, trailing: false },
)

function getMimeType(fileData) {
    const fileInfo = fileTypeChecker.detectFile(fileData)

    return fileInfo?.mimeType
}

const handleFileClip = memoizeDebounce(
    ({ data: b64data, mimeType, content, ...extra }) => {
        try {
            const fileData = Uint8Array.from(atob(b64data), c => c.charCodeAt(0))
            if (!mimeType) {
                mimeType = getMimeType(fileData)
            }
            const clipParams = { ...(content ? { content } : {}) }
            if (mimeType.startsWith('image/')) {
                clipParams['image'] = fileData
            } else if (mimeType.startsWith('video/')) {
                clipParams['video'] = fileData
            } else {
                clipParams['file'] = fileData
            }
            if (extra?.destination) {
                clipParams.destination = extra?.destination
            }
            return api.clipLink(clipParams).then(clippedQuest => {
                // debugPopover(`clipped res: ${JSON.stringify(Object.keys(clippedQuest))}`)
                redirectToQuest(clippedQuest)
            })
        } catch (e) {
            debugPopover(`Error handling file share: ${e}\nStack Trace: ${e.stack}`)
        }
    },
    1000,
    { leading: false, trailing: true },
)

function initMobileAppEventHandlers() {
    if (!window.KNOVIGATOR_IS_MOBILE) return
    console.log('>>> Activating mobile event handlers')

    eventBus$
        .pipe(
            filter(msg => msg.Source !== MsgSource.Web),
            filter(msg => msg.type === MsgTypes.ShareUrl),
        )
        .subscribe(msg => {
            try {
                handleUrlClip(JSON.parse(msg.content))
            } catch (e) {
                // debugPopover(`grrr: ${e}`)
            }
            replyTo(eventBus$, msg, MsgSource.Web, 'ok')
        })

    eventBus$
        .pipe(
            filter(msg => msg.Source !== MsgSource.Web),
            filter(msg => msg.type === MsgTypes.ShareFile),
        )
        .subscribe(msg => {
            try {
                handleFileClip(JSON.parse(msg.content))
            } catch (e) {
                // debugPopover(`grrr: ${e}`)
            }
            replyTo(eventBus$, msg, MsgSource.Web, 'ok')
        })

    eventBus$
        .pipe(
            filter(msg => msg.source !== MsgTypes.Web),
            filter(msg => msg.type === MsgTypes.Notification),
        )
        .subscribe(msg => {
            handleMobileNotiClicked(JSON.parse(msg.content))
            replyTo(eventBus$, msg, MsgSource.Web, 'ok')
        })

    // forward webapp messages to the native app
    eventBus$.pipe(filter(msg => msg.source === MsgSource.Web)).subscribe(msg => {
        // alert(`>>> WEBAPP msg! ${msg.toJson()}`)
        window.ReactNativeWebView.postMessage(msg.toJson())
    })

    // reply to ping (heartbeat) messages
    eventBus$
        .pipe(
            filter(msg => msg.source !== MsgSource.Web),
            filter(msg => msg.type === MsgTypes.Ping),
        )
        .subscribe(msg => {
            replyTo(eventBus$, msg, MsgSource.Web, `pong ${new Date().toISOString()}`)
        })

    // forward incoming msgs from expo to eventbus$
    document.addEventListener(
        'knovigatorMsg',
        event => {
            // alert(`received message! msg: ${JSON.stringify(event.detail)}`)
            const incomingMsg = Message.fromJson(event.detail)
            eventBus$.next(incomingMsg)
        },
        false,
    )
}

function initAmplitude(userId) {
    if (window?.amplitude) {
        console.log('Initializing amplitude')
        amplitude.getInstance().init('af9082f410808de56a1082ad60c29920', userId)
    } else {
        console.log('window.amplitude not found, not loading!')
    }
}

function initSentry() {
    Sentry.init({
        dsn: 'https://f0a6a944bf3a414894e3eb908cdf6fdb@o455929.ingest.sentry.io/5448411',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: true,
                blockAllMedia: true,
                errorSampleRate: 1.0,
            }),
        ],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
        environment: gon?.env,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
    })
}

function initAxios() {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = gon.formToken
    axios.defaults.headers.common['X-CLIENT-VERSION'] = gon.clientVersion
}

mountKnovApp()
