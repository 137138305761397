import React, { useState, useEffect } from 'react'
import useStore from 'state/knovStore'
import UserIcon from 'components/users/UserIcon'
import UserEditForm from './UserEditForm'
import CableApp from '../../actioncable'
import api from '../../api/api'
import cc from 'cryptocompare'
import cn from 'classnames'
import styles from './profile.module.scss'
import WithTooltip from 'components/shared/WithTooltip'
import Button, { PurpleButton } from 'components/shared/buttons/Button'
import { isMobile } from 'react-device-detect'
import UserImageUpdate from 'components/modals/UserImageUpdate'

export default function EditProfile(props) {
    const [user, setUser] = useState(window.gon.currentUser)
    const [balance, setBalance] = useState(null)
    const [potentialBsv, setPotentialBsv] = useState(props.potentialBsv)
    const [potentialUsd, setPotentialUsd] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [updatedAt, setUpdatedAt] = useState(new Date().getTime())
    const [mutedUsers, setMutedUsers] = useState(gon?.currentUser?.muted_users)
    const [showImageUpdate, setShowImageUpdate] = useState(false)

    useEffect(function getUserSpaces() {
        api.getSpaces().then(spaces => {
            set({ userSpaces: spaces })
        })
    }, [])

    useEffect(() => {
        if (user && potentialBsv) {
            const getUsd = async () => {
                const price = await cc.price('bsv', 'usd')
                if (price && price['USD']) {
                    const bsv = (potentialBsv * price['USD']).toFixed(2)
                    setPotentialUsd(bsv)
                } else {
                    setPotentialUsd(0)
                }
            }
            getUsd()
        }
    }, [potentialBsv])

    useEffect(() => {
        if (user) {
            CableApp.channel = CableApp.cable.subscriptions.create(
                { channel: 'UserChannel', userId: user.id },
                {
                    received: async data => {
                        if (data.message === 'balance updated') {
                            setBalance(data.balance)
                            setIsLoading(false)
                            if (data.hasOwnProperty('potentialBsv')) {
                                setPotentialBsv(data.potentialBsv)
                            }
                        }
                    },
                    connected: api.requestBalance,
                },
            )
        }
        return () => {
            if (CableApp.channel && CableApp.channel.unsubscribe) {
                CableApp.channel.unsubscribe()
            }
        }
    }, [])

    const toggleEmailNoti = async e => {
        e.preventDefault()
        const email_noti = !(user.email_noti === true || user.email_noti === null)
        const data = await api.updateUser(user.id, {
            email_noti,
        })
        if (data.user) {
            setUser(data.user)
        } else {
            setErrors(data.errors)
        }
    }

    const emailNotiState = user.email_noti === true || user.email_noti === null ? 'On' : 'Off'
    const emailNotiBtn =
        user.email_noti === true || user.email_noti === null ? 'Email Off' : 'Email On'

    const showHandcash =
        gon.currentUser &&
        gon.currentUser.features &&
        gon.currentUser.features.some(feature => feature.name === 'handcash')

    const handleImageUpdateComplete = newUser => {
        setUpdatedAt(new Date().getTime())
        setUser(newUser)
        setShowImageUpdate(false)
    }

    const toggleUserFeature = async featureName => {
        const response = await api.toggleUserFeature(featureName)
        if (response.features) {
            const newFeatures = {
                ...user,
                features: response.features.map(name => ({ name })),
            }
            setUser(newFeatures)

            const newGon = await api.getGon()
            if (newGon?.currentUser) {
                // update the global gon to make sure the rest of the app knows about it
                window.gon = newGon
            }
        }
    }

    const toggleEnterToPost = () => toggleUserFeature('enter_key_posts_immediately')

    const hasFeature = featureName => user.features?.some(f => f.name === featureName)

    return (
        <div className={styles.profileContainer}>
            <div className={cn(styles.profileComp, isMobile && styles.mobileProfileComp)}>
                <div className={styles.userAvatarForm}>
                    <div className={styles.header}>
                        <div className={styles.userLogo} onClick={() => setShowImageUpdate(true)}>
                            <UserIcon
                                user={user}
                                updatedAt={updatedAt}
                                contextStyles={styles.userIcon}
                                size={'medium'}
                            />
                        </div>
                        <div className={styles.userName}> {user.name} </div>
                    </div>
                </div>

                {showImageUpdate && (
                    <div className={styles.imageUpdateOverlay}>
                        <div className={styles.imageUpdateContainer}>
                            <div className={styles.imageUpdateHeader}>
                                <h3>Update Profile Picture</h3>
                                <button
                                    className={styles.closeButton}
                                    onClick={() => setShowImageUpdate(false)}
                                >
                                    <i className="fa fa-remove" />
                                </button>
                            </div>
                            <div>
                                <UserImageUpdate
                                    modalParams={{
                                        user,
                                        callback: handleImageUpdateComplete,
                                    }}
                                    closeModal={() => setShowImageUpdate(false)}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {showHandcash && (
                    <>
                        {!props.withHandcash && potentialBsv > 0 && (
                            <div className={styles.integrationContainer}>
                                <div>{`Integrate Handcash to claim $${potentialUsd} of upvalue.`}</div>
                            </div>
                        )}

                        <div className={styles.integrationContainer}>
                            Your Handcash upvalue balance:{' '}
                            {isLoading ? <i className={'fa fa-spin fa-spinner'} /> : `$${balance}`}
                        </div>

                        <div className={styles.integrationContainer}>
                            <a id="connectButton" href={props.handcashRegistrationUrl}>
                                Connect with Handcash
                            </a>
                        </div>
                    </>
                )}

                <div className={styles.editUserFormContainer}>
                    <div className={styles.sectionHeader}>Profile Information</div>
                    <div>
                        <UserEditForm user={user} setUser={setUser} />
                    </div>
                </div>

                <div className={styles.notiContainer}>
                    <div className={styles.sectionHeader}>Posting Settings</div>

                    <div className={styles.emailNotiContainer}>
                        <div className={styles.emailNoti}>
                            <span className={styles.emailNotiLabel}>Enter to Post</span>
                            <div className={styles.emailNotiDescription}>
                                Post messages by pressing Enter.
                            </div>
                        </div>

                        <label className={styles.toggleSwitch}>
                            <input
                                type="checkbox"
                                checked={hasFeature('enter_key_posts_immediately')}
                                onChange={toggleEnterToPost}
                            />
                            <span className={styles.toggleSlider}></span>
                        </label>
                    </div>

                    <div className={styles.emailNotiContainer}>
                        <div className={styles.emailNoti}>
                            <span className={styles.emailNotiLabel}>
                                Confirm Before Posting Publicly
                            </span>
                            <div className={styles.emailNotiDescription}>
                                Ask for confirmation before posting publicly
                            </div>
                        </div>

                        <label className={styles.toggleSwitch}>
                            <input
                                type="checkbox"
                                checked={hasFeature('confirm_before_posting_publicly')}
                                onChange={() =>
                                    toggleUserFeature('confirm_before_posting_publicly')
                                }
                            />
                            <span className={styles.toggleSlider}></span>
                        </label>
                    </div>
                </div>

                <div className={styles.notiContainer}>
                    <div className={styles.sectionHeader}>Notification Settings</div>

                    <div className={styles.emailNotiContainer}>
                        <div className={styles.emailNoti}>
                            <span className={styles.emailNotiLabel}>
                                Instant Email Notifications
                            </span>
                            <div className={styles.emailNotiDescription}>
                                Receive instant email notifications along with push notifications
                            </div>
                        </div>

                        <label className={styles.toggleSwitch}>
                            <input
                                type="checkbox"
                                checked={user.email_noti === true || user.email_noti === null}
                                onChange={toggleEmailNoti}
                            />
                            <span className={styles.toggleSlider}></span>
                        </label>
                    </div>
                </div>

                {mutedUsers && mutedUsers.length > 0 && (
                    <div className={styles.muteListContainer}>
                        <div className={styles.muteListHeader}>Muted Users</div>

                        <div className={styles.muteList}>
                            {user?.muted_users.map(mutedUser => {
                                return (
                                    <div className={styles.mutedUserContainer}>
                                        <div className={styles.mutedUser}>
                                            <div className={styles.mutedUserIcon}>
                                                <UserIcon
                                                    user={mutedUser}
                                                    contextStyles={styles.mutedUserIcon}
                                                    size={'small'}
                                                />
                                            </div>

                                            <div className={styles.mutedUserName}>
                                                {mutedUser.name}
                                            </div>
                                        </div>

                                        <div className={styles.mutedUserButtonContainer}>
                                            <PurpleButton
                                                text="Unmute"
                                                onClick={async () => {
                                                    await api.unmuteUser(mutedUser.id)
                                                    setMutedUsers(
                                                        mutedUsers.filter(
                                                            user => user.id !== mutedUser.id,
                                                        ),
                                                    )
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
