import React, { useState, useMemo } from 'react'
import { useEffect } from 'react'
import api from 'api/api'
import useStore from 'state/knovStore'
import styles from 'components/modals/wallet.module.scss'
import Button from 'components/shared/buttons/Button'
import { toBitcoin, toSats } from 'lib/bsv-util'
import cn from 'classnames'
import { useTxChannel } from 'state/channels/useUserChannel'
import { BsvWalletType, PandaWallet, SHUAllet, initWallet } from 'wallets/wallets'
import useWalletDetails from 'api/useWalletDetails'
import { useSatsToDollars, formatUsdValue } from 'api/useBsvExchangeRate'

export default function Wallet({ closeModal }) {
    const currentUserBsvWalletType = useStore(state => state.currentUserBsvWalletType)
    const { data: walletDetailsData, isLoading: isInitialLoading } =
        useWalletDetails(currentUserBsvWalletType)

    // loading is only finished when both balance and address are ready
    const isLoading =
        isInitialLoading || !walletDetailsData?.balanceLoaded || !walletDetailsData?.addressLoaded

    const currentUserBsvBalance = walletDetailsData?.balance || 0
    const currentUserBsvAddress = walletDetailsData?.address || ''

    const wallet = walletDetailsData?.wallet
    const set = useStore.getState().set

    const DEBUG = useStore(state => state.DEBUG)
    const [amountSats, setAmountSats] = useState<number | ''>('')
    const [address, setAddress] = useState('')
    const [sending, setSending] = useState(false)

    // to fund panda the funds need to be sent to what panda refers to as the wallet's `bsvAddress`, but
    // locks and other parts of the app that look at `currentUserBsvAddress` still need to use what panda
    // refers to as the wallets `identityAddress`, so we override `currentUserBsvAddress` in this case
    const [pandaDisplayAddress, setPandaDisplayAddress] = useState('')
    const isPandaAvailable = useState(!!window.panda)
    const [isFragmenting, setIsFragmenting] = useState(false)

    useEffect(() => {
        async function walletInstantiator() {
            const wallet = initWallet(currentUserBsvWalletType)
            await wallet?.authenticate?.()
            if (wallet?.type() === BsvWalletType.PANDA) {
                setPandaDisplayAddress((await window.panda?.getAddresses())?.bsvAddress)
            } else {
                try {
                    // values will get set asynchronously via the socket
                    wallet?.getAddress()
                    wallet?.getBalance()
                } catch (err) {
                    console.error('Error updating wallet state:', err)
                    // Don't clear existing values on error
                }
            }
        }
        walletInstantiator()
    }, [currentUserBsvWalletType])

    useTxChannel({
        successCallback: txId => {
            setSending(false)
            alert(`Transaction ${txId} sent.`)
        },
        errorCallback: errorMessage => {
            setSending(false)
            alert(`Error sending transaction: ${errorMessage}`)
        },
    })

    return (
        <div>
            <div className={styles.balanceContainer}>
                {false && isLoading && (
                    <div className={styles.loading}>
                        <i className="fa fa-spinner fa-spin" />
                    </div>
                )}
                <div>
                    <i className="fa fa-btc" />
                    {currentUserBsvBalance === 0 && isLoading
                        ? 'Balance loading...'
                        : toBitcoin(currentUserBsvBalance)}{' '}
                    <span style={isLoading ? { display: 'none' } : {}}>
                        ({formatUsdValue(useSatsToDollars(currentUserBsvBalance))} USD)
                    </span>
                    <div className={styles.confirmedUnconfirmedContainer}>
                        <div>
                            Confirmed:{' '}
                            {walletDetailsData?.confirmedBalance === 0 ? (
                                '0'
                            ) : walletDetailsData?.confirmedBalance ? (
                                toBitcoin(walletDetailsData?.confirmedBalance)
                            ) : (
                                <i className="fa fa-spinner fa-spin" />
                            )}
                        </div>
                        <div>
                            Unconfirmed:{' '}
                            {walletDetailsData?.unconfirmedBalance === 0 ? (
                                '0'
                            ) : walletDetailsData?.unconfirmedBalance ? (
                                toBitcoin(walletDetailsData?.unconfirmedBalance)
                            ) : (
                                <i className="fa fa-spinner fa-spin" />
                            )}
                        </div>
                    </div>
                    {/* <div>
                        <pre>{JSON.stringify(walletDetailsData, null, 2)}</pre>
                    </div> */}
                </div>
            </div>

            {false && (
                <div className={styles.walletChoiceContainer}>
                    {isPandaAvailable && (
                        <div
                            className={cn(styles.walletChoiceBtn, styles.yoursWallet, {
                                [styles.activeWallet]:
                                    currentUserBsvWalletType === BsvWalletType.PANDA,
                            })}
                            onClick={async e => {
                                set({ currentUserBsvWalletType: BsvWalletType.PANDA })
                                api.updateUserOptions({
                                    bsv_wallet_type: BsvWalletType.PANDA,
                                })
                                api.setPandaPubkey(
                                    gon.currentUser.id,
                                    (await window.panda.getPubKeys())?.identityPubKey,
                                )
                            }}
                        >
                            <img
                                src="/yours512.png"
                                alt="Yours Wallet Logo"
                                className={styles.walletLogo}
                            />
                            <div className={styles.walletName}>Yours Wallet</div>
                        </div>
                    )}

                    <div
                        className={cn(styles.walletChoiceBtn, styles.shualletWallet, {
                            [styles.activeWallet]:
                                currentUserBsvWalletType === BsvWalletType.SHUALLET,
                        })}
                        onClick={e => {
                            set({ currentUserBsvWalletType: BsvWalletType.SHUALLET })
                            api.updateUserOptions({
                                bsv_wallet_type: BsvWalletType.SHUALLET,
                            })
                        }}
                    >
                        <img
                            src="/SHUAllet.png"
                            alt="SHUAllet Logo"
                            className={styles.walletLogo}
                        />
                        <div className={styles.walletName}>SHUAllet</div>
                    </div>
                </div>
            )}

            {DEBUG && (
                <div
                    className={cn(styles.refragmentButton, { [styles.disabled]: isFragmenting })}
                    onClick={async e => {
                        e.preventDefault()
                        if (isFragmenting) return

                        try {
                            setIsFragmenting(true)
                            const response = await api.createFragmentWalletTx(10, 0.5, true)

                            if (response.error) {
                                alert(`Error fragmenting wallet: ${response.error}`)
                            } else if (response.broadcast_result?.error) {
                                alert(
                                    `Error broadcasting transaction: ${response.broadcast_result.error}`,
                                )
                            } else {
                                alert('Wallet successfully refragmented')
                                // refresh balance after fragmenting
                                wallet?.getBalance()
                            }
                        } catch (err) {
                            console.error('Error fragmenting wallet:', err)
                            alert('Failed to fragment wallet. Please try again.')
                        } finally {
                            setIsFragmenting(false)
                        }
                    }}
                >
                    <i className={`fa fa-cube ${styles.iconStyle}`} />
                    <span className={styles.refragmentText}>
                        {isFragmenting ? 'Fragmenting...' : 'Refragment Wallet'}
                    </span>
                </div>
            )}

            <div className={styles.depositContainer}>
                <div className={styles.depositHeader}>Receive</div>

                <div>
                    <div className={styles.addressRow}>
                        <div className={styles.addressLabel}>Address:</div>
                        {(() => {
                            const addr =
                                currentUserBsvWalletType === BsvWalletType.PANDA
                                    ? pandaDisplayAddress
                                    : currentUserBsvAddress
                            return (
                                <span className={styles.addressDisplay}>
                                    <a
                                        href={`https://whatsonchain.com/address/${addr}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {addr}
                                    </a>
                                    <button
                                        onClick={() => {
                                            navigator.clipboard.writeText(addr)
                                            const icon = document.querySelector('.clipboard-icon')
                                            icon.classList.add(
                                                `${styles.clipboardIcon}-clipboardClick`,
                                            )
                                            setTimeout(() => {
                                                icon.classList.remove(
                                                    `${styles.clipboardIcon}-clipboardClick`,
                                                )
                                            }, 200)
                                        }}
                                        className={styles.clipboardButton}
                                        aria-label="Copy address to clipboard"
                                    >
                                        <i
                                            className={`fa fa-clipboard clipboard-icon ${styles.clipboardIcon}`}
                                        />
                                    </button>
                                </span>
                            )
                        })()}
                    </div>
                </div>
            </div>

            {currentUserBsvWalletType !== BsvWalletType.PANDA && (
                <>
                    <div className={styles.withdrawContainer}>
                        <div className={styles.withdrawHeader}>Send</div>

                        <div className={styles.addressControl}>
                            <div className={styles.inputLabel}>Destination Address</div>
                            <div className={styles.inputContainer}>
                                <input
                                    className={cn(styles.input, 'wallet-input')}
                                    type="text"
                                    value={address}
                                    onChange={e => setAddress(e.target.value)}
                                    placeholder="Enter destination address."
                                />
                            </div>
                        </div>

                        <div className={styles.amountControl}>
                            <div className={styles.inputLabel}>Amount (bitcoin)</div>
                            <div className={styles.inputContainer}>
                                <input
                                    className={cn(styles.input, 'wallet-input')}
                                    type="number"
                                    value={
                                        typeof amountSats === 'number' ? toBitcoin(amountSats) : ''
                                    }
                                    onChange={ev => {
                                        setAmountSats(
                                            ev.target.value ? toSats(Number(ev.target.value)) : '',
                                        )
                                    }}
                                    placeholder="Enter amount in bitcoin."
                                />
                            </div>
                        </div>

                        <div className={styles.sendButtonWrapper}>
                            {currentUserBsvWalletType === BsvWalletType.SHUALLET && (
                                <a
                                    href="#"
                                    onClick={async e => {
                                        e.preventDefault()
                                        try {
                                            const blob = await api.downloadBsvWallet()
                                            const url = window.URL.createObjectURL(blob)
                                            const link = document.createElement('a')
                                            link.href = url
                                            link.setAttribute('download', 'treechat_shuallet.json')
                                            document.body.appendChild(link)
                                            link.click()
                                            link.parentNode.removeChild(link)
                                        } catch (error) {
                                            console.error('Error downloading wallet:', error)
                                            alert('Wallet not found or error occurred.')
                                        }
                                    }}
                                >
                                    Download Shuallet
                                </a>
                            )}

                            <Button
                                disabled={!amountSats || !address}
                                inProgress={sending}
                                onClick={async () => {
                                    if (amountSats && currentUserBsvBalance >= Number(amountSats)) {
                                        setSending(true)
                                        wallet.sendBsv(address, amountSats)
                                    } else alert('Insufficient funds.')
                                }}
                                text="Send"
                                contextStyles={styles.sendBtn}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
