import React from 'react'
import Vote from 'components/votes/Vote'
import TweetEmbed from 'react-tweet-embed'
import VidCommands from './VidCommands'
import UrlPost from './UrlPost'
import parseDomain from 'parse-domain'
import styles from './url-answer.module.scss'
import cn from 'classnames'
import WithSelection from '../shared/WithSelection'
import WithTooltip from 'components/shared/WithTooltip'
import { LinkPreview, twitterRegexp, knovLinkRegexp } from 'components/shared/LinkPreview'

export default class UrlAnswer extends React.PureComponent {
    constructor(props) {
        super(props)
    }

    isUserSignedIn() {
        return $('#knovigator-body').attr('data-user') === 'true'
    }

    showVotes = () => {
        return typeof this.props.showVotes === 'undefined' ? true : this.props.showVotes
    }

    squash = ev => {
        ev.stopPropagation()
        ev.preventDefault()
    }

    voteContainerParent = React.createRef()

    showVidCommands = () => this.props.answer.yt_app && this.props.command

    isTwitterUrl = url => {
        return twitterRegexp.test(url?.address)
    }

    renderBsvAttachmentStatus = matchingBsvAttachment => {
        const bsvTx = matchingBsvAttachment?.bsv_tx
        const txId = bsvTx?.tx_id

        // Check if we have a pending transaction that's more than 5 seconds old
        const isAwaitingResubmission =
            bsvTx && !txId && (new Date() - new Date(bsvTx.created_at)) / 1000 > 5

        // Show different status for old pending transactions
        if (isAwaitingResubmission) {
            const retryInfo = bsvTx.retries > 0 ? ` (${bsvTx.retries} retries)` : ''
            return (
                <WithTooltip
                    tip={`Treechat wasn't able to post this onchain immediately but will try again on the next block ${retryInfo}`}
                >
                    <i className="fa fa-clock-o" />
                </WithTooltip>
            )
        }

        // Show spinner for new pending transactions
        if (!txId) {
            return (
                <WithTooltip tip="Transaction pending">
                    <i className="fa fa-spinner fa-spin" />
                </WithTooltip>
            )
        }

        const isUnconfirmed = !bsvTx?.confirmed

        return (
            <WithTooltip tip={`${isUnconfirmed ? 'Unconfirmed' : 'Confirmed'} tx: ${txId}`}>
                <a
                    href={`https://whatsonchain.com/tx/${txId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={ev => ev.stopPropagation()}
                >
                    <i className={`fa fa-cube`} />
                </a>
            </WithTooltip>
        )
    }

    render() {
        const answer = this.props.answer
        const url = answer.url
        const address = answer.url && answer.url.address ? answer.url.address : ''
        const parse = parseDomain(address)
        let domain
        if (parse) {
            domain = `${parse.domain}.${parse.tld}`
        }
        const editStyles = this.props.editMode ? styles.edit : null
        const isEmbedStyles = this.props.isEmbed ? styles.isEmbed : null
        const embedUrlContainerStyles = this.props.isEmbed ? styles.embedUrlContainer : null
        const commandContainerTitleStyles = this.props.isTitle ? styles.commandContainerTitle : null

        // Find matching BsvAttachment for this URL's full text
        const matchingBsvAttachment = answer?.bsv_attachments?.find(
            att => att.attachment_id === url?.full_text_attachment_id,
        )

        return (
            <div className={styles.urlAnswerComp}>
                {this.showVidCommands() && (
                    <div className={cn(styles.commandContainer, commandContainerTitleStyles)}>
                        <VidCommands
                            command={this.props.command}
                            vidStart={this.props.vidStart}
                            vidLoop={this.props.vidLoop}
                            comment={answer}
                            getYtPlayer={this.props.getYtPlayer}
                            vidLoaded={this.props.vidLoaded}
                            loadVid={this.props.loadVid}
                        />
                    </div>
                )}

                <div className={cn(styles.flexContainer)}>
                    {/*this.props.showVotes &&
                        <div className={cn(styles.voteContainer)} ref={this.voteContainerParent}>
                            <Vote
                                votable={this.props.answer}
                                votableType="Answer"
                                votableId={this.props.answer.id}
                                voteTotal={this.props.answer.vote_total}
                                cryptoVoteTotal={this.props.answer.crypto_vote_total}
                                userVote={this.props.answer.current_user_vote}
                                userSignedIn={this.isUserSignedIn()}
                                hover={this.props.answerHover}
                                updateVotable={this.props.updateAnswer}
                                displayCrypto={this.props.answer.crypto_vote_total > 0}
                                parentRef={this.voteContainerParent}
                                toggleShowKnovigator={this.props.toggleShowKnovigator}
                            />
                        </div>
                    */}

                    <div className={cn(styles.userPostContent, editStyles, isEmbedStyles)}>
                        {this.props.answer.url &&
                            !knovLinkRegexp.test(this.props.answer.url.address) && (
                                <div
                                    className={cn(styles.urlContainer, embedUrlContainerStyles)}
                                    data-answer={this.props.answer.id}
                                >
                                    <div className={styles.urlWithLogo}>
                                        <div className={styles.sourceContainer}>
                                            <div className={cn(styles.source)}>
                                                <div
                                                    className={cn(
                                                        styles.sourceHeader,
                                                        'source-header',
                                                    )}
                                                >
                                                    {!this.props.isYoutube && (
                                                        <div className={styles.linkPreviewWrapper}>
                                                            <LinkPreview
                                                                url={this.props.answer.url.address}
                                                                domain={domain}
                                                                inViewCallback={
                                                                    this.props.inViewCallback
                                                                }
                                                                urlModel={this.props.answer.url}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            {url?.selection && !this.isTwitterUrl(url) && (
                                                <div
                                                    className={cn(
                                                        styles.selectionContainer,
                                                        'web-quote-outer',
                                                    )}
                                                    data-answer={this.props.answer.id}
                                                >
                                                    <WithSelection>
                                                        <div
                                                            className={cn(
                                                                styles.selection,
                                                                'web-quote-inner',
                                                            )}
                                                            data-answer-id={this.props.answer.id}
                                                        >
                                                            {this.props.answer.url.selection.replace(
                                                                /&nbsp;/,
                                                                ' ',
                                                            )}
                                                        </div>
                                                    </WithSelection>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {matchingBsvAttachment && (
                                        <div className={styles.bsvStatusOverlay}>
                                            {this.renderBsvAttachmentStatus(matchingBsvAttachment)}
                                        </div>
                                    )}
                                </div>
                            )}
                    </div>
                </div>
            </div>
        )
    }
}
