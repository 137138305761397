import React, { useEffect } from 'react'
import styles from 'components/urls/twitter-url.module.scss'
import cn from 'classnames'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import parseDomain from 'parse-domain'

export default function TwitterUrl(props) {
    const url = props.url

    return (
        <a
            className={styles.twitterUrlComp}
            onClick={ev => ev.stopPropagation()}
            href={`https://twitter.com/${url.service_json.author.username}/status/${url.service_json.id}`}
            target="_blank"
        >
            <div className={styles.userHeader}>
                <div className={styles.avatar}>
                    <img src={url.avatar_url} />
                </div>

                <div className={styles.nameContainer}>
                    <div className={styles.screenName}>{url.service_json?.author?.name}</div>

                    <div className={styles.name}>@{url.service_json?.author?.username}</div>
                </div>

                <div className={styles.twitterIcon}>
                    <i className="fa fa-twitter" />
                </div>
            </div>

            <div className={styles.contentContainer}>
                <div className={styles.selection}>
                    {
                        // limit display to 280 characters, splitting on whitespace
                        url.selection && url.selection.length > 280
                            ? url.selection.split(/\s+/).reduce((acc, word) => {
                                  if (acc.length + word.length + 1 <= 277) {
                                      return acc.length ? `${acc} ${word}` : word
                                  }
                                  return acc
                              }, '') + '...'
                            : url.selection
                    }
                </div>
            </div>

            {url.video_urls?.length > 0 && (
                <div className={styles.videoContainer}>
                    <video
                        controls
                        playsInline
                        preload="metadata"
                        src={url.video_urls[0]}
                        type="video/mp4"
                    />
                </div>
            )}

            {url.image_urls?.length > 0 && (
                <div className={styles.imageContainer}>
                    <img src={url.image_urls[0]} />
                </div>
            )}

            {url.embed && url.embed.service_name === 'twitter' && (
                <div className={styles.urlEmbedContainer}>
                    <TwitterUrlEmbed url={url.embed} />
                </div>
            )}

            {url.embed && url.embed.service_name === 'website' && (
                <div className={styles.urlEmbedContainer}>
                    <WebsiteUrlEmbed url={url.embed} />
                </div>
            )}

            {url.service_json?.created_at && (
                <div className={styles.dateTimeContainer}>
                    {moment(url.service_json.created_at).format('LT · LL')}
                </div>
            )}
        </a>
    )
}

function TwitterUrlEmbed(props) {
    const url = props.url

    return (
        <div className={styles.twitterUrlEmbedComp}>
            <div className={styles.embedUserHeader}>
                <div className={cn(styles.avatar, styles.embedAvatar)}>
                    <img src={url.avatar_url} />
                </div>

                <div className={cn(styles.screenName, styles.embedScreenName)}>
                    {url.service_json?.author?.name}
                </div>

                <div className={cn(styles.name, styles.embedName)}>
                    @{url.service_json?.author?.username}
                </div>
            </div>

            <div className={styles.contentContainer}>
                <div className={styles.selection}>{url.selection}</div>
            </div>

            {url.video_urls?.length > 0 && (
                <div className={styles.embedVideoContainer}>
                    <video
                        controls
                        playsInline
                        preload="metadata"
                        src={url.video_urls[0]}
                        type="video/mp4"
                    />
                </div>
            )}

            {url.image_urls?.length > 0 && (
                <div className={styles.embedImageContainer}>
                    <img src={url.image_urls[0]} />
                </div>
            )}
        </div>
    )
}

function WebsiteUrlEmbed(props) {
    const url = props.url
    const imageUrls = url.image_urls
    const imgUrl = imageUrls.length ? imageUrls[imageUrls.length - 1] : null
    const displayUrl = url.service_json['display_url']

    const address = url.service_json['unwound_url']

    const navigateToUrl = url => {
        window.open(address, '_blank')
    }

    const parse = parseDomain(address)
    let domain,
        img = null
    if (parse) {
        domain = `${parse.domain}.${parse.tld}`
        //img = `https://www.google.com/s2/favicons?sz=32&domain_url=${domain}`
        img = `https://icon.horse/icon/${domain}?size=small`
    }

    return (
        <div className={styles.webEmbedComp} onClick={() => navigateToUrl(url)}>
            {imgUrl ? (
                <div
                    className={styles.webImageContainer}
                    style={{
                        backgroundImage: `url("${imgUrl}")`,
                    }}
                ></div>
            ) : (
                <div className={styles.webImagePlaceholder}>
                    <img src={img} />
                </div>
            )}

            <div className={styles.webContentContainer}>
                <div className={styles.displayUrl}>{displayUrl}</div>

                <div className={styles.title}>{url.title}</div>

                <div className={styles.description}>{url.selection}</div>
            </div>
        </div>
    )
}
