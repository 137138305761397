import React, {
    useRef,
    useState,
    useLayoutEffect,
    useCallback,
    ReactNode,
    useEffect,
    useMemo,
} from 'react'
import { PanelContextProvider } from 'refactor/hooks/usePanelContext'
import { isMobile } from 'react-device-detect'
import cn from 'classnames'
import styles from 'components/panels/panel-container.module.scss'
import { getCenterIndex } from 'state/imperativeApis/swiperApi'
import { SMALL, BIG, transformTo, getTransform } from 'lib/transform'
import { debounce } from 'lodash'
import useStore from 'state/knovStore'
import { PanelState } from 'state/PanelState'
import { Filter } from 'components/filters/useStreamFilters'

declare global {
    interface Window {
        isPanelScrolling: ReturnType<typeof setTimeout> | null
    }
}

type ScrollDirection = 'up' | 'down' | null

interface PanelContainerProps {
    panel: PanelState
    index: number
    hide?: boolean
    children: ReactNode
    isEmpty?: boolean
}

export default React.memo(function PanelContainer({
    panel,
    index,
    hide,
    children,
    isEmpty,
}: PanelContainerProps) {
    // Only subscribe to panel changes for this specific panel
    const panelCount = useStore(state => state.panels.state.length)
    const panelContainerRef = useRef<HTMLDivElement>(null)
    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const [isVisible, setIsVisible] = useState(true)
    const [externalHideScrollButton, setExternalHideScrollButton] = useState<boolean>(false)
    const lastScrollTop = useRef(0)
    const scrollingTimeoutRef = useRef<number | null>(null)
    const scrollButtonContainerRef = useRef<HTMLDivElement>(null)
    const hideTimeoutRef = useRef<number | null>(null)

    const onPanelScroll = useCallback((ev: React.UIEvent<HTMLDivElement>) => {
        if (scrollingTimeoutRef.current) {
            window.cancelAnimationFrame(scrollingTimeoutRef.current)
        }

        scrollingTimeoutRef.current = window.requestAnimationFrame(() => {
            const scrollContainer = scrollContainerRef.current
            const scrollButtonContainer = scrollButtonContainerRef.current
            if (!scrollContainer || !scrollButtonContainer) return

            const offset = 0
            const scrollTop = scrollContainer.scrollTop
            const scrollHeight = scrollContainer.scrollHeight
            const clientHeight = scrollContainer.clientHeight
            const atTop = scrollTop <= offset
            const atBottom = scrollTop >= scrollHeight - clientHeight - offset
            const hasQuestFilter = !!panel.filter?.questId

            scrollButtonContainer.classList.remove(styles.scrollUp, styles.scrollDown)

            if (atTop || atBottom) return

            if (hasQuestFilter) {
                const scrollDirection =
                    scrollTop > lastScrollTop.current ? 'scrollDown' : 'scrollUp'
                scrollButtonContainer.classList.add(styles[scrollDirection])
            } else {
                scrollButtonContainer.classList.add(styles.scrollUp)
            }
            lastScrollTop.current = scrollTop

            // Hide scroll button after delay
            if (hideTimeoutRef.current) {
                clearTimeout(hideTimeoutRef.current)
            }
            hideTimeoutRef.current = window.setTimeout(() => {
                scrollButtonContainer?.classList.remove(styles.scrollUp, styles.scrollDown)
            }, 2000)

            scrollingTimeoutRef.current = null
        })
    }, [])

    const handleScrollButtonClick = useCallback(() => {
        if (scrollContainerRef.current) {
            const isScrollingUp = scrollButtonContainerRef.current?.classList.contains(
                styles.scrollUp,
            )
            scrollContainerRef.current.scrollTo({
                top: isScrollingUp ? 0 : scrollContainerRef.current.scrollHeight,
                behavior: 'smooth',
            })
        }
    }, [])

    useLayoutEffect(
        function performTransform() {
            if (isMobile || !panelContainerRef.current) return

            const transformContainer =
                panelContainerRef.current?.querySelector<HTMLElement>('.transform-container')
            const scrollContainer =
                panelContainerRef.current?.querySelector<HTMLDivElement>('.scroll-container')
            if (!transformContainer || !scrollContainer) return

            // Batch all reads
            const reads = () => {
                const centerIndex = getCenterIndex()
                const transformObject = getTransform(transformContainer.style.transform)
                const currentScale = transformObject.scale || 1
                return { centerIndex, currentScale }
            }

            // Batch all writes
            const writes = ({ centerIndex, currentScale }) => {
                if (centerIndex === index && currentScale === SMALL) {
                    transformTo(BIG, panel)
                } else if (centerIndex !== index && currentScale === BIG) {
                    transformTo(SMALL, panel)
                }
            }

            // Schedule reads then writes
            const frame = requestAnimationFrame(() => {
                const measurements = reads()
                writes(measurements)
            })

            return () => cancelAnimationFrame(frame)
        },
        [index],
    )

    // We  memoize the panel context so it does not cause re-renders when passed to the provider below.
    const panelContext = React.useMemo(
        () => ({
            panel,
            panelId: panel.panelId,
            filter: panel.filter,
            hide,
            panelContainerRef,
            scrollContainerRef,
            setExternalHideScrollButton,
        }),
        [panel.panelId, panel.filter, hide],
    )

    useEffect(() => {
        if (!panelContainerRef.current || isMobile) return

        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    setIsVisible(entry.isIntersecting)
                })
            },
            {
                root: null, // Use viewport
                rootMargin: '0px 100% 0px 100%', // 100% horizontal margin to match panel width, 200px vertical margin for early loading
                threshold: 0.1, // Show when at least 10% is visible
            },
        )

        observer.observe(panelContainerRef.current)

        return () => {
            if (panelContainerRef.current) {
                observer.unobserve(panelContainerRef.current)
            }
            observer.disconnect()
        }
    }, [])

    useEffect(() => {
        return () => {
            if (scrollingTimeoutRef.current) {
                window.cancelAnimationFrame(scrollingTimeoutRef.current)
            }
            if (hideTimeoutRef.current) {
                clearTimeout(hideTimeoutRef.current)
            }
        }
    }, [])

    return (
        <PanelContextProvider value={panelContext}>
            <div
                style={{
                    width: '100%',
                    visibility: isVisible ? 'visible' : 'hidden',
                }}
                className={cn(
                    isMobile && 'is-mobile',
                    styles.panelContainer,
                    'panel-container-comp',
                )}
                ref={useCallback(ref => {
                    if (!ref) {
                        return
                    }

                    panelContainerRef.current = ref
                    const panelRef = panel.getPanelRef()
                    if (panelRef !== ref) {
                        panel.setPanelRef(ref)
                        const prevScrollTop = panelRef?.scrollTop
                        if (prevScrollTop) {
                            ref.scrollTop = prevScrollTop
                        }
                    }
                }, [])}
            >
                <div
                    ref={scrollContainerRef}
                    className={cn('scroll-container', styles.scrollContainer, 'h100')}
                    onScroll={onPanelScroll}
                    style={{
                        display: isVisible ? 'block' : 'none',
                    }}
                >
                    <div
                        className={cn(
                            styles.transformContainer,
                            `panel-${panel.panelId}`,
                            'transform-container h100',
                            isMobile && 'is-mobile',
                            'will-change-transform',
                        )}
                    >
                        {children}
                    </div>
                </div>

                {!externalHideScrollButton && (
                    <div
                        ref={scrollButtonContainerRef}
                        className={cn(styles.scrollButtonContainer)}
                    >
                        <div
                            className={cn(
                                styles.scrollButton,
                                isMobile && styles.mobileScrollButton,
                                !isMobile &&
                                    getCenterIndex() === index &&
                                    styles.centerScrollButton,
                            )}
                            onClick={handleScrollButtonClick}
                        >
                            <div className={styles.scrollButtonFace}>
                                <i className="fa fa-chevron-up" />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </PanelContextProvider>
    )
})
